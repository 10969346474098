import MKBox from 'components/MKBox';
import MKButton from 'components/MKButton';
import SearchIcon from '@mui/icons-material/Search';

import React, { useState } from 'react'
import { Container, AppBar, InputBase } from '@mui/material';
import colors from 'assets/theme/base/colors';
import MKTypography from 'components/MKTypography';

const SearchBar = ({ placeholder, onSearch }) => {
  const [search, setSearch] = useState('');

  return (
    <>
    <style>
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
    </style>
      <AppBar position="sticky" elevation={0} sx={{ boxShadow: 'none', backgroundColor: colors.background.theme }}>
        <Container maxWidth="md">
          <MKTypography 
            variant="h4" 
            sx={{ 
              fontWeight: 'bold', 
              textAlign: 'left', 
              mb: 2,
              mt: 2,
              color: colors.white.main, 
              fontFamily: "Montserrat, sans-serif" 
            }}
          >
            SEARCH TO RENT SPACE
          </MKTypography>
          <MKBox
            component="form"
            onSubmit={(e) => {
              e.preventDefault();
              onSearch();
            }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              backgroundColor: '#ffffff',
              borderRadius: 2.5,
              border: '1px solid grey',
              padding: '5px 18px',
              my:2,
            }}
          >
            <SearchIcon
              sx={{ 
                  scale: '1.5',
                  color: 'rgb(110,110,110)',
                  marginRight: "5px"
              }} 
            />
            <InputBase
              placeholder={placeholder}
              value={search}
              onChange={
                (e) => {
                  setSearch(e.target.value);
                }
              }
              sx={{
                ml: 1,
                flex: 1,
                fontSize: '1rem',
                fontWeight: 'bold',
                color: 'rgb(0,0,0)',
                fontSize: "20px",
                fontFamily: "Montserrat, sans-serif"
              }}
            />
            <MKButton type="submit" sx={{ display: 'none' }}>Search</MKButton>
          </MKBox>
        </Container>
      </AppBar>
    </>
  );
};

export default SearchBar;