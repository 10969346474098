import { Icon } from "@mui/material";

import ViewDay from "@mui/icons-material/ViewDay";
import Article from "@mui/icons-material/Article";
import Dashboard from "@mui/icons-material/Dashboard";
import Login from "@mui/icons-material/Login";
import Help from "@mui/icons-material/Help";
import Logout from "@mui/icons-material/Logout";

export const launch_routes = [
  {
    name: "Home",
    icon: <Icon><Dashboard /></Icon>,
  },
  {
    name: "Benefits",
    icon: <Icon><ViewDay /></Icon>
  },
  {
    name: "FAQ",
    icon: <Icon><Article /></Icon>,
  }
];

export const home_routes = [
  {
    name: "Help",
    icon: <Icon><Help /></Icon>,
  },
  {
    name: "Login",
    icon: <Icon><Login /></Icon>
  }
];