import { Fragment, useState, useEffect } from "react";

import { Link } from "react-router-dom";

import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import Menu from "@mui/icons-material/Menu"
import Close from "@mui/icons-material/Close"

import MKBox from "components/MKBox";
import MKButton from "components/MKButton";

import breakpoints from "assets/theme/base/breakpoints";

import colors from "assets/theme/base/colors"
import NavbarDropdown from "./dropdown";
import NavbarMobile from "./webMobile";

const Navbar = ({
  action,
  height,
  routes,
  shadow,
  sticky,
  imglogo,
  nav_text_color
}) => {

  const [mobileView, setMobileView] = useState(false);
  const [mobileNavbar, setMobileNavbar] = useState(false);

  const renderNavbarItems = routes.map(({ name, icon, href, route }) => (
    <NavbarDropdown
      key={name}
      name={name}
      icon={icon}
      href={href}
      route={route}
      nav_text_color={nav_text_color}
      onClick={() => {
        document.getElementById(name).scrollIntoView({behavior:"smooth", block:"start"})
      }}
    />
  ));

  useEffect(() => {
    const resize = () => {
      setMobileView(window.innerWidth < breakpoints.values.lg);
    }

    window.addEventListener("resize", resize);
    resize();
    return () => window.removeEventListener("resize", resize);
  }, []);

  return (
    <MKBox
      shadow= {shadow ? "md" : "none"}
      
      sx={{
        width:'100%',
        height: {height},
        color: colors.text.focus,
        backgroundColor: colors.background.theme,
        position: sticky ? "sticky" : "relative", 
        top: 0, 
        zIndex: 10
      }}
    >
      <Container>
        <MKBox display="flex" justifyContent="space-between" alignItems="center">
          <MKBox
            component={Link}
            to=""
            lineHeight={1}
            py={1.5}
            // pl={relative || transparent ? 0 : { xs: 0, lg: 1 }}
          >
            <img src={imglogo} width="150px"/>
          </MKBox>
          <MKBox
            color="inherit"
            display={{ xs: "none", lg: "flex" }}
            ml="auto"
          >
            {renderNavbarItems}
          </MKBox>
          <MKBox ml={{ xs: "auto", lg: 0 }}>
            <MKButton
              component={Link}
              onClick={action.onclick}
              color="info" // BOLD NAV BAR BUTTON
              size="small"
            >
              {action.label}
            </MKButton>
          </MKBox>
          <MKBox
            display={{ xs: "inline-block", lg: "none" }}
            lineHeight={0}
            py={1.5}
            pl={1.5}
            color={colors.background.default} // DROPDOWN HAMBURGER TRIPLE LINE
            sx={{ cursor: "pointer" }}
            onClick={() => setMobileNavbar(!mobileNavbar)}
          >
            <Icon fontSize="default">{mobileNavbar ? <Close /> : <Menu />}</Icon>
          </MKBox>
        </MKBox>
        <MKBox
          bgColor={colors.background.theme}
          shadow={"lg"}
          borderRadius="xl"
          px={2}
        >
          {mobileView && <NavbarMobile routes={routes} open={mobileNavbar} nav_text_color={nav_text_color}/>}
        </MKBox>
      </Container>
    </MKBox>
  )
}

export default Navbar;