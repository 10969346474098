import sparelot_llc_logo from "assets/logos/sparelot_llc_logo.png"
export const company_logo = sparelot_llc_logo;

export const text_color = "grey";
export const title_color = "white";
export const light_mode = true;
export const light_text_color = "white";
export const dark_text_color = "grey";
export const light_dark_text = light_mode ? light_text_color : dark_text_color;

export const title_text_color_hex = "#ffffff"
export const subtitle_text_color_hex = "#bbbbbb";
export const background_theme_color = '#051d40';

export const domain_name = "sparelot.com"

export const faq_data_hosts = [
    { question: "How do I create my first listing?", answer: "To create your first listing, you must fill out all the information about your space including location, unique features, access availability, and any other necessary notes. Next, the host must take pictures of their space for storage seekers to view. This does not require any equipment and can be done using almost any mobile device. Finally, decide on a price using our proprietary recommendation system and wait for the reservations to roll in."},
    { question: "How do hosts get paid?", answer: "Hosts receive payments by connecting their bank accounts and are paid automatically after each rental period. This is done using Stripe, our secure payment system."},
    { question: "How are hosts protected?", answer: "While SpareLot is not liable for damage to property, hosts are protected through a number of in-app safety features. For example, our storage-seeker background checks and review system ensure that each renter is well-qualified and well-intentioned. Additionally, hosts are able to decline reservations within a 24-hour period if they do not feel comfortable with the request."}
]

export const faq_data_seekers = [
    { question: "How do I reserve a rental space?", answer: "You can reserve a rental space by simply creating an account, filtering for your type and size of required space, and searching within your location. Then, SpareLot makes it easy to reserve your space, make your first payment, and get in contact with your host."},
    { question: "How can I trust my host?", answer: "Storage-seekers can trust that all hosts on SpareLot are background checked and meet all legal requirements to list their space. Furthermore, users can reference the in-app review system for hosts to check that their host is well-regarded by past storage-seekers."},
    { question: "Will my items be safe?", answer: "Renters can rest assured that their items will remain safe when in storage. If any items were to be damaged, the host will be penalized and the renter will recieve compensation."},
    { question: "How much does it cost and how do payments work?", answer: "The price of each space listing is decided upon by the host with a recommendation from our pricing system. However, storing or parking with SpareLot is almost always more economical than traditional storage or parking facilities. Payments are made automatically at the end of each storage period, which could be weekly or monthly. If a renter fails to make a payment, they will be charged a late fee at the end of a 10-day margin."},
    { question: "Are there places to store near me?", answer: "SpareLot utilizes a user to user based ecosystem, meaning that it is much more likely to find SpareLot listings near you than traditional storage facilities. Additionally, by existing users recruiting more hosts, we can expand to more locations and offer even more storage options."},
    { question: "How often can I access my items?", answer: "Access frequency is specified by hosts but is usually lenient. For storage functionalities such as parking, users can negotiate with their hosts for unrestricted access."},
    { question: "Are there limits on the length of storage time?", answer: "Users must be storing for at least one week but do not have any limit on the amount of time their items can be stored for."},
    { question: "Can SpareLot also be used for parking?", answer: "Yes! One of SpareLot's primary functionalities is parking—whether that be one time parking for an event or long term parking for school or work."},
    { question: "How can I contact my host?", answer: "Renters can contact their hosts through our in-app messaging system. There, you can negotiate storage terms, discuss access restrictions, ask about unique space perks, and more."}
]

export const faq_data = faq_data_seekers.concat(faq_data_hosts);

export const email_sub_register = "Receieve Launch Updates";
export const email_sub_disclaimer = "We will never share your information with anyone"

// URLS
export const subscribed_href = "/subscribed"