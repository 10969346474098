import { Container } from "@mui/material";
import SearchBar from "app/sections/Search/SearchBar";
import Navbar from "app/sections/Navbar";

import * as c from "const"
import colors from "assets/theme/base/colors";
import { home_routes } from "app/sections/Navbar/routes";

const Home = () => {
    return (
        <>
            {/* THIS WORKS */}
            {/* <Navbar
                action={{
                type: "external",
                onclick: () => { document.getElementById("FooterSubscribe").scrollIntoView({behavior:"smooth", block:"start"})},
                label: "Become a Host",
                color: "info",
                }}
                routes={home_routes}
                sticky={true} // add if you want the navbar to stay with you while you scroll
                shadow={true}
                imglogo={c.company_logo}
                nav_text_color={colors.white.main}
            /> */}
            {/* THIS WORKS */}

            <SearchBar
                placeholder="Enter Location or ZIP"
            />
            <Container>
                
            </Container>
        </>
    )
}

export default Home;