import { useEffect } from "react";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "assets/theme";

// pages
import Home from "app/pages/Home"
import Launch from "app/pages/Launch"
import SubscribeConfirmation from "app/pages/Subscribe/Confirmation";

import * as c from "const"

export default function App() {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route path="*" element={<Launch />} />
        <Route path="/home" element={<Home />} />
        <Route path={c.subscribed_href} element={<SubscribeConfirmation />} />
      </Routes>
    </ThemeProvider>
  );
}