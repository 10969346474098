async function add_email_subscriber(name, email) {
  const functionUrl = 'https://us-central1-spare-lot-app.cloudfunctions.net/add_email_subscriber';

  try {
    const response = await fetch(functionUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ name, email })
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Function call error: ${errorText}`);
    }

    const responseData = await response.text();
  } catch (error) {
    console.error('Error:', error.message);
  }
}

export default add_email_subscriber;