import PropTypes from "prop-types";

import { Link } from "react-router-dom";

import Collapse from "@mui/material/Collapse";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import colors from "assets/theme/base/colors";

const NavbarDropdown = ({
  name,
  icon,
  children,
  collapseStatus,
  light,
  href,
  route,
  nav_text_color,
  collapse,
  ...rest
}) => {
  const linkComponent = {
    component: "a",
    href,
    target: "_blank",
    rel: "noreferrer",
  };

  const routeComponent = {
    component: Link,
    to: route,
  };

  return (
    <>
      <MKBox
        {...rest}
        mx={1}
        p={1}
        display="flex"
        alignItems="baseline"
        color={nav_text_color}
        sx={{ 
          cursor: "pointer", 
          userSelect: "none"
        }}
        {...(route && routeComponent)}
        {...(href && linkComponent)}
      >
        <MKTypography
          variant="body2"
          lineHeight={1}
          color={colors.text.main}
          sx={{ alignSelf: "center", "& *": { verticalAlign: "middle" } }}
        >
          {icon}
        </MKTypography>
        <MKTypography
          variant="button"
          fontWeight="bold"
          textTransform="capitalize"
          color={colors.text.main}
          sx={{ ml: 1, mr:2 }}
        >
          {name}
        </MKTypography>
      </MKBox>
      {children && (
        <Collapse in={Boolean(collapseStatus)} timeout={400} unmountOnExit>
          {children}
        </Collapse>
      )}
    </>
  );
}

// Setting default values for the props of DefaultNavbarDropdown
NavbarDropdown.defaultProps = {
  children: false,
  collapseStatus: false,
  light: false,
  href: "",
  route: "",
};

// Typechecking props for the DefaultNavbarDropdown
NavbarDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  children: PropTypes.node,
  collapseStatus: PropTypes.bool,
  light: PropTypes.bool,
  href: PropTypes.string,
  route: PropTypes.string,
  collapse: PropTypes.bool.isRequired,
};

export default NavbarDropdown;